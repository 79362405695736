import { Meta } from "../common";


export const FAQs = () => {
  return (
    <div className="about pageHeight">
      <Meta title='Frequently Asked Questions (FAQs)' description='Together Corporation' />

      <div className="pt-10 pb-2">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto">
            Frequently Asked Questions (FAQs)
          </h1>
        </div>
        <div className="xl:px-0 px-10 ">
          <p className="p2">&nbsp;</p>
          <ul className="ul1">
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">What is Together Corporation and what does it do?</span>
              Together Corporation is a global conglomerate headquartered in Washington DC, operating across three main business verticals: Together Tourism, TogetherEd, and Together Threads. We specialize in educational tourism, tech-driven collaborations between academia and industry, and sustainable fashion.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">How does TogetherEd work?</span>
              TogetherEd facilitates collaborations between students and industries to solve real-world problems using cutting-edge technology. Students gain hands-on experience, and industries benefit from innovative solutions.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">What kind of educational tourism does Together Tourism offer?</span>
              Together Tourism provides unique educational travel experiences for students, combining learning with exploration. Our tours focus on cultural immersion, industrial sites, and educational interactions worldwide.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">What sets Together Threads apart in the fashion industry?</span>
              Together Threads stands out by emphasizing sustainability and ethical practices in the fashion apparel import and export business. We prioritize responsible sourcing and production methods.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">How can I get involved with Together Corporation?</span>
              Explore our website to learn more about our initiatives and opportunities. For specific inquiries or collaborations, feel free to contact us through our website's contact page.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">What sustainability initiatives does Together Corporation support?</span>
              Together Corporation is committed to sustainability across its operations. Our initiatives include ethical sourcing, environmental responsibility, community engagement, and innovation for sustainability.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">Are there career opportunities available at Together Corporation?</span>
              We occasionally have openings across our business verticals. Visit our Careers page for current job listings and information on how to apply.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">How does Together Corporation contribute to local communities?</span>
              We engage with local communities through various initiatives focused on education, environmental conservation, and social welfare. We support programs that create a positive impact in the communities where we operate.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold">Can I partner with Together Corporation for collaborative projects?</span>
              We welcome collaborations that align with our values and goals. Please reach out to us with your proposals or ideas through our contact page.</li>
            <li className="border py-5 border-x-0 border-t-0 border-slate-400">
              <span className="block font-bold"> How does Together Corporation prioritize sustainability in its operations?</span>
              Sustainability is a core value at Together Corporation. We prioritize ethical sourcing, environmental responsibility, and transparency in our sustainability efforts.</li>
          </ul>
          <p className="p4">&nbsp;</p>
        </div>

      </div>
    </div>
  );
}
