import { GiClothes } from "react-icons/gi";
import { LuSparkles } from "react-icons/lu";
import { MdHistoryEdu } from "react-icons/md";
import { PiHandshake } from "react-icons/pi";
import { RiLightbulbFlashLine, RiTeamLine } from "react-icons/ri";
import { SiYourtraveldottv } from "react-icons/si";
import { Meta } from "../common";
import { ImgFile } from "../common/image";

export const OurStory = () => {

  const mottoBlocks = [
    {
      name: "Together's Tale",
      desc: "In a world of learning, let's endeavour, Together Corp, our joy, forever! From explorations that we tether, To innovations we craft together.",
      img: <MdHistoryEdu color="#09203f" size={'5rem'} />
    },
    {
      name: "A Spark of Vision",
      desc: "From the lands where dreams take flight, Together's saga began with a vision bright. An edu-tourism magic brew, Learning's adventure, fresh and new!",
      img: <LuSparkles color="#09203f" size={'5rem'} />
    },
    {
      name: "Bridge of Innovation",
      desc: "TogetherEd emerged, a bridge so grand, Uniting academia and industry's hand. Innovators nurtured, challenges unfurled, Minds shaped, as new ideas swirled.",
      img: <RiLightbulbFlashLine color="#09203f" size={'5rem'} />
    },
    {
      name: "Threads of Elegance",
      desc: "Threads joined the party, threads so chic, Sustainable fashion, no room for a tweak. From catwalks to exports, a stylish spree, Threads and ethics are the coolest keys!",
      img: <GiClothes color="#09203f" size={'5rem'} />
    },
    {
      name: "Global Symphony",
      desc: "Across the world, our reach extends, From diverse cultures, we blend and mend. From India's embrace to America's shore, Our narrative expands, forevermore",
      img: <SiYourtraveldottv color="#09203f" size={'5rem'} />
    },
    {
      name: "Values Unite",
      desc: "Innovation, excellence, our guiding light, Collaboration, resilience, gleaming bright. Integrity's touch, sustainability's embrace, Our values converge, creating grace.",
      img: <RiTeamLine color="#09203f" size={'5rem'} />
    },
    {
      name: "Invitation to Unite",
      desc: "So come and join, let's journey together, Together Corp, a joyous tether! A story of laughter, now and forever, At Together Corp, we thrive together!",
      img: <PiHandshake color="#09203f" size={'5rem'} />
    },
  ]

  return (
    <div className="about pageHeight">
      <Meta title='Our Story' description='Discover the journey of Together Corporation, from inception to innovation, shaping businesses and empowering individuals worldwide.' />

      <div className="bgShapeSection hidden xl:block">
        <div className="bgShape bgShapeGray"></div>
        <div className="bgShape bgShapeBlack"></div>
        <div className="bgShape heroSubPageImage slide-up">
          <ImgFile imageName="ourstory" />
        </div>
      </div>

      <div className="pt-6 pb-2 px-5 xl:px-0 xl:w-6/12">
        <div className="mt-1 mb-6 xl:mt-12 xl:mb-6 slide-up delay-1">
          <span className="folksTitle text-5xl font-semibold">Our Story</span>
          <h1 className="folksDesc text-2xl xl:text-2xl mx-auto">
            We aim to empower them to change the world
          </h1>
        </div>
        <div className="x-5 xl:px-0 slide-up delay-2">
          <p className="mb-3">From India's vibrant embrace, Together Corporation emerged, weaving a narrative of learning, resilience, and growth.</p>
          <p className="mb-3">Our voyage began in 2009 with Together Tourism, blending education with exploration, sparking curiosity worldwide. Amidst success, the COVID-19 storm struck, testing our mettle. But adversity became our catalyst for innovation, fortifying our spirit.</p>
          <p className="mb-3">Expanding our horizons, TogetherEd united academia and industry, empowering minds with technology during uncertain times. This resilience led us to Together Threads, where sustainable style flourished, adapting to a changing world.</p>
          <p className="mb-3">In a stride toward new frontiers, Together Corporation now embraces the United States. Our journey isn't just about triumphs; it's about fortitude and the human spirit's resilience.</p>
          <p className="mb-3">Join us in this journey of revival and unwavering aspiration. Together, let's script a future where resilience defines success, and our shared experiences shape a world of endless possibilities.</p>
        </div>
      </div>

      <div className="pt-6 pb-2 px-5 xl:px-0 xl:w-8/12">
        <div className="mt-1 mb-6 xl:mt-12 xl:mb-6 slide-up delay-1">
          <span className="folksTitle text-5xl font-semibold">Our Journey</span>
        </div>
        <div className="flex flex-wrap">
          {
            mottoBlocks?.map((item, i) =>
              <div className="serviceSecBox flex items-start" key={i}>
                <div className="serviceSecBoxIcon p-4"> {item.img} </div>
                <div className="serviceBoxContentWrapper hover:shadow-lg hover:scale-110 slide-up">
                  <div className="serviceSecBoxTitle font-bold folksTitle pb-2"> {item.name} </div>
                  <div className="serviceSecBoxText text-sm">
                    {item.desc}
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
