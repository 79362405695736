import { MdAutoGraph, MdOutlineHdrStrong } from "react-icons/md";
import { PiFireBold, PiUsersThreeBold } from "react-icons/pi";
import { LiaUserShieldSolid } from "react-icons/lia";
import { Meta } from "../common";
import { ImgFile } from "../common/image";

export const OurCoreValues = () => {
  const clients = ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9'];

  const mottoBlocks = [
    {
      name: "Innovation",
      desc: "Sparking creativity, seeking new solutions, and embracing fresh perspectives.",
      img: <PiFireBold color="#09203f" size={'5rem'} />
    },
    {
      name: "Excellence",
      desc: "Setting high standards, delivering quality in every endeavor undertaken.",
      img: <MdAutoGraph color="#09203f" size={'5rem'} />
    },
    {
      name: "Collaboration",
      desc: "Fostering teamwork, valuing diverse ideas, and working towards shared goals.",
      img: <PiUsersThreeBold color="#09203f" size={'5rem'} />
    },
    {
      name: "Resilience",
      desc: "Adapting and persisting, facing challenges with determination and  spirit",
      img: <MdOutlineHdrStrong color="#09203f" size={'5rem'} />
    },
    {
      name: "Integrity",
      desc: "Upholding honesty, transparency, and ethical conduct in all actions",
      img: <LiaUserShieldSolid color="#09203f" size={'5rem'} />
    }
  ];
  return (
    <div className="about pageHeight">
      <Meta title='Our Core Values' description='Leading through action' />

      <div className="bgShapeSection hidden xl:block">
        <div className="bgShape bgShapeGray"></div>
        <div className="bgShape bgShapeBlack"></div>
        <div className="bgShape heroSubPageImage slide-up">
          <ImgFile imageName="corevalue" />
        </div>
      </div>

      <div className="pt-6 pb-2 px-5 xl:px-0 xl:w-6/12">
        <div className="mt-1 mb-6 xl:mt-12 xl:mb-6 slide-up delay-1">
          <span className="folksTitle text-5xl font-semibold">Our Core Values</span>
          <h1 className="folksDesc text-2xl xl:text-2xl mx-auto">
            Leading through action
          </h1>
        </div>
        <div className="x-5 xl:px-0 slide-up delay-2">
          <div className="flex flex-wrap">
            {
              mottoBlocks?.map((item, i) =>
                <div className="serviceSecBox flex items-start" key={i}>
                  <div className="serviceSecBoxIcon p-4"> {item.img} </div>
                  <div className="serviceBoxContentWrapper hover:shadow-lg hover:scale-110 slide-up">
                    <div className="serviceSecBoxTitle font-bold folksTitle pb-2"> {item.name} </div>
                    <div className="serviceSecBoxText text-sm">
                      {item.desc}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>


      <div className="pt-10 pb-10 xl:w-6/12">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <span className="folksRed">The Clients</span>
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto">
            We Work With the Very Best
          </h1>
        </div>
        <div className="my-5 p-4 xl:p-10">
          <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 justify-center items-center">
            {clients.map((item, index) => <ImgFile imageName={item} key={index} width="100px" />)}
          </div>
        </div>

      </div>

    </div>
  );
}
