import { NavLink } from "react-router-dom";
import { Meta } from "../common";
import { ImgFile } from "../common/image";


export const Togethered = () => {
  return (
    <div className="about pageHeight">
      <Meta title='TogetherEd' description="Empowering minds through technology for tomorrow's challenges" />

      <div className="pt-10 pb-2 px-6 xl:px-0">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6 slide-up delay-1">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto">
            Together<span className="folksRed">Ed</span>
          </h1>
        </div>
        <div className="text-center px-5 xl:px-0 slide-up delay-2">
          <p className="mb-3">At TogetherEd, we specialize in marrying the prowess of academia with industry needs, driving innovative solutions through technology-driven problem-solving. Our platform serves as the nexus where the bright minds of tomorrow converge with real-world industrial challenges, fostering a mutually beneficial relationship</p>
          <div className='flex justify-center'>
            <NavLink className={'mx-5 xl:mx-0 slide-up py-2 cursor-pointer px-4 w-56 text-center text-white font-semibold rounded mt-4 consultButton'} to={'/business/togethered/case-studies'}>Case Studies</NavLink>
          </div>
        </div>
        <div className="my-5 p-4 xl:p-10">
          <div className="clientSecs justify-evenly">
            <div className="flex flex-wrap xl:flex-nowrap justify-center">
              <div className="xl:w-1/4 lg:w-2/5 md:w-2/5 sm:w-2/5 p-5 relative pr-0 mr-5 slide-up delay-4">
                <ImgFile imageName="blocked1" className="z-0 rounded overflow-hidden" />
                <div className="bg-white z-1 p-5 rounded relative mt-52">
                  <div className="folksTitle font-semibold text-lg leading-none pb-5">Revolutionizing Solutions with Technology</div>
                  <div className="">
                    Harnessing the brilliance of our students, we provide industries with forward-thinking solutions leveraging the latest technologies. Our students, well-versed in cutting-edge tech and methodologies, eagerly delve into industry challenges, offering inventive and tech-driven perspectives.
                  </div>
                </div>

              </div>
              <div className="xl:w-1/4 lg:w-2/5 md:w-2/5 sm:w-2/5 p-5 relative pr-0 mr-5 slide-up delay-5">
                <ImgFile imageName="blocked2" className="z-0 rounded overflow-hidden" />
                <div className="bg-white z-1 p-5 rounded relative mt-52">
                  <div className="folksTitle font-semibold text-lg leading-none pb-5">A Tech-Infused Win-Win</div>
                  <div className="">
                    Partnering with TogetherEd grants industries access to a pool of tech-savvy problem solvers ready to tackle complex challenges. Through collaboration, industries gain access to groundbreaking research, fresh talent, and solutions infused with innovative technology.
                  </div>
                </div>

              </div>
              <div className="xl:w-1/4 lg:w-2/5 md:w-2/5 sm:w-2/5 p-5 relative pr-0 slide-up delay-6">
                <ImgFile imageName="blocked3" className="z-0 rounded overflow-hidden" />
                <div className="bg-white z-1 p-5 rounded relative mt-52">
                  <div className="folksTitle font-semibold text-lg leading-none pb-5">Empowering Tech   Proficient Students</div>
                  <div className="">
                    For students, it's an immersive journey in technology-fueled problem-solving. They dive into real-world challenges, gaining hands-on experience and refining sought-after tech skills, preparing them for the dynamic demands of the workforce
                  </div>
                </div>

              </div>
              <div className="xl:w-1/4 lg:w-2/5 md:w-2/5 sm:w-2/5 p-5 relative pr-0 ml-5 slide-up delay-7">
                <ImgFile imageName="blocked4" className="z-0 rounded overflow-hidden" />
                <div className="bg-white z-1 p-5 rounded relative mt-52">
                  <div className="folksTitle font-semibold text-lg leading-none pb-5">Pioneering Tech-Forward Partnerships</div>
                  <div className="">
                    Together, our focus on technology-driven solutions forges powerful partnerships between industry and academia. This synergy creates an environment where innovation flourishes, industries evolve, and tech-proficient students become the driving force behind transformative change.
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto folksRed slide-up delay-8">
            We help Small Businesses
          </h1>
        </div>
        <div className="text-center px-5 xl:px-0 xl:w-1/2 mx-auto slide-up delay-9">
          <p className="mb-3">We help small businesses to grow and expand with no or low stress and minimize expenses by utilizing students to solve real-time problems resulting in cost-effective efficiency</p>
        </div>
        <div className='flex justify-center'>
          <NavLink className={'mx-5 xl:mx-0 slide-up py-2 cursor-pointer px-4 w-56 text-center text-white font-semibold rounded mt-4 consultButton'} to={'https://calendly.com/togetheredtech'} target="blank">Get Started</NavLink>
        </div>
      </div>
    </div>
  );
}
