import React, { useRef, Fragment, useState } from 'react';
import emailjs from '@emailjs/browser';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { ImgFile } from './image';

interface CareerInternshioFormProps {
  title?: string;
  desc?: string;
  className?: string;
}

const businessOption = [
  {
    id: 1,
    name: 'Together Tourism',
    avatar: 'tour',
  },
  {
    id: 2,
    name: 'TogetherEd',
    avatar: 'thereads',
  },
  {
    id: 3,
    name: 'Together Threads',
    avatar: 'togethered'
  }
]

const socialOption = [
  {
    id: 1,
    name: 'Company Website',
    avatar: '',
  },
  {
    id: 2,
    name: 'LinkedIn',
    avatar: '',
  },
  {
    id: 3,
    name: 'Team Referral',
    avatar: ''
  },
  {
    id: 4,
    name: 'Recruitment Agency',
    avatar: '',
  },
  {
    id: 5,
    name: 'Career Fair',
    avatar: '',
  },
  {
    id: 6,
    name: 'Others',
    avatar: ''
  }
]


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const CareerInternshioForm = (props: CareerInternshioFormProps) => {
  const form = useRef(null);
  const [onSuccess, setOnSuccess] = useState(false);
  const headTitle = props?.title ? props?.title : "Start Your Journey with Together";
  const headDesc = props?.desc ? props?.desc : "Please write to us.";
  const className = props?.className ? props?.className : "";
  const [selected, setSelected] = useState(businessOption[3])
  const [selectedRef, setSelectedRef] = useState(socialOption[3])

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs.sendForm('service_x5lrkqb', 'template_z92xu5b', e?.target, 'jzEOZkzRMqv1OoYBA')
      .then((result) => {
        console.log(result.text);
        setOnSuccess(true)

      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div>
      {!onSuccess ?
        <form ref={form} onSubmit={sendEmail} className={className}>
          <div className="space-y-12s">

            <div className="border-b border-gray-900/10 pb-6">
              <h2 className="text-2xl font-semibold leading-7 text-gray-900">{headTitle}</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">{headDesc}</p>

              <div className="mt-5 grid grid-cols-1 gap-2 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="user_name" className="block text-sm font-medium leading-6 text-gray-900">
                    Full name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="user_name"
                      id="user_name"
                      autoComplete="given-name" required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                    Phone
                  </label>
                  <div className="mt-2">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      autoComplete="given-name" required
                      title='Only Phone number'
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="user_email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="user_email"
                      name="user_email"
                      type="email"
                      autoComplete="user_email" required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
                    Birthday
                  </label>
                  <div className="mt-2">
                    <input
                      id="date"
                      name="date"
                      type="date"
                      autoComplete="date" required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Where do you want to contribute</Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                              <ImgFile imageName={selected?.avatar} className='h-5 w-5 flex-shrink-0' />
                              <span className="ml-3 block truncate">{selected?.name}</span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {businessOption.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={person}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex items-center">
                                        <ImgFile imageName={person?.avatar} className='h-5 w-5 flex-shrink-0 ' />
                                        <span
                                          className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                        >
                                          {person.name}
                                        </span>
                                      </div>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-indigo-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div className="col-span-full">
                  <label htmlFor="aboutyou" className="block text-sm font-medium leading-6 text-gray-900">
                    About You
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="aboutyou"
                      name="aboutyou"
                      rows={3} required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={''}
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="skills" className="block text-sm font-medium leading-6 text-gray-900">
                    Describe your Skills | Experience
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="skills"
                      name="skills"
                      rows={3} required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={''}
                    />
                  </div>
                </div>


                <div className="sm:col-span-6">
                  <Listbox value={selectedRef} onChange={setSelectedRef}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">You are here because of</Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                              {/* <ImgFile imageName={selectedRef?.avatar} className='h-5 w-5 flex-shrink-0' /> */}
                              <span className="ml-3 block truncate">{selectedRef?.name}</span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {socialOption.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={person}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex items-center">
                                        {/* <ImgFile imageName={person?.avatar} className='h-5 w-5 flex-shrink-0 ' /> */}
                                        <span
                                          className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                        >
                                          {person.name}
                                        </span>
                                      </div>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-indigo-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

              </div>
            </div>
            <input
              type="text"
              name="business"
              id="business"
              autoComplete="given-name"
              className="hidden"
              value={selected?.name}
            />
            <input
              type="text"
              name="reference"
              id="reference"
              autoComplete="given-name"
              className="hidden"
              value={selectedRef?.name}
            />
            <div className="mt-3 flex items-center justify-end gap-x-6">
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700"
              >
                Send
              </button>
            </div>
          </div>
        </form> :

        <div className='bg-white shadow-xl rounded p-14 flex flex-col justify-center items-center'>
          <div className='animate-bounce'>
            <BsFillCheckCircleFill size={'5rem'} color='#5cb85c' />
          </div>
          <div className='mt-5'>We've received your interest. Thank you!</div>
        </div>
      }
    </div>
  );
}
