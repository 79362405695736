import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import ham from '../assets/images/icons/ham.svg'
import { TfiClose } from "react-icons/tfi";
import { DropDown } from './dropdown'
import { aboutDropdown, businessDropdown, hamBurgerMinks } from './routes'
import '../styles/common.scss'
import { ImgFile } from './image';

export const Header = () => {
  const [variable, setVariable] = useState(false);
  const handleClick = () => {
    setVariable(!variable);
  }

  return (
    <div className="container mx-auto w-full flex justify-between py-4 px-4 xl:px-0">
      <div className="logo flex items-center">
        <Link to="/"><ImgFile imageName='logo' width='120' /></Link>

        <div className="ml-10 md:ml-16 hidden md:flex xl:flex justify-center items-center transition-transform">
          <DropDown menuName='About' menuList={aboutDropdown} className='w-24 text-center' activeClass='folksRed' />

          <DropDown menuName='Business' menuList={businessDropdown} className='w-24 text-center' activeClass='folksRed' />

          <NavLink className={({ isActive }) => isActive ? 'w-24 text-center text-base menuHover folksRed links' : 'text-base w-24 text-center menuHover'} to={'contact'}>Contact</NavLink>

        </div>
      </div>
      <div className="headerRightNav md:hidden">
        <img src={ham} className="cursor-pointer" width="40" alt="" onClick={handleClick} />
        <div className={(variable ? 'opened' : 'closed') + ' navigationPane'}>
          <div className="text-white cursor-pointer closeIco" onClick={handleClick}>
            <TfiClose color='#ffffff' size={'2rem'} />
          </div>
          <div className="flex flex-col p-10">
            {
              hamBurgerMinks.map((item, index) => <NavLink key={index} onClick={handleClick} className={({ isActive }) => isActive ? 'navPaneList active' : 'navPaneList'} to={item.url}>{item.name}</NavLink>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}
