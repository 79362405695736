import { Meta, TalkToAConsultant } from "../common";
import { ImgFile } from "../common/image";

export const CaseStudies = () => {
  var works = [
    {
      title: 'Parking Systems',
      description: 'Alert the user about the Parking place availability and allow them to book a parking place before voyage.',
      image: "Parking-Systems"
    },
    {
      title: 'Internal Ticketing System',
      description: 'Simple, intuitive and easy to use Internal Ticketing system eases the support system and expedite the resolution turnaround time.',
      image: "Internal-Ticketing-System"
    },
    {
      title: 'AI-Powered Interior Boutique',
      description: 'Compares the most advanced Boutique available in the market and picks the one that suits your need the most.',
      image: "AI-Powered-Interior-Boutique"
    },
    {
      title: 'Blockchain for Internal Transactions',
      description: 'Convert your Network to a successful business model by incorporating Blockchain driven Internal Transactions.',
      image: "Blockchain-for-Internal-Transactions"
    },
    {
      title: 'AI-Powered Eyecare Systems',
      description: 'Detect all the eye diseases at the earliest stage without a dedicated visit to an ophthalmologist.',
      image: "AI-Powered-Eyecare-Systems"
    },
    {
      title: 'Enterprise Process Management',
      description: 'Automate & System Integrate the entire process of your organisation to achieve the Optimal value of the time and energy.',
      image: "Enterprise-Process-Management"
    },
    {
      title: 'Enterprise Document Management',
      description: 'Receive, Archive, Retrieve, Validate, Sign-Off Documents on the go at minimal cost. Use our workflow management to manage your document at ease.',
      image: "Enterprise-Document-Management"
    },
    {
      title: 'Internal Chat-bot System',
      description: 'Simplify your help desk, bring on accuracy and save resources by implementing Internal Chat-bot system.',
      image: "Internal-Chat-bot-System"
    },
    {
      title: 'Document OCR',
      description: 'Capture Everything and anything from everywhere to convert the data to a business value.',
      image: "Document-OCR"
    },
    {
      title: 'Code Analysis and Bug Tracker',
      description: 'Help your developer to concentrate on more valuable activities and we take care of code optimization to match current environment.',
      image: "Code-Analysis-and-Bug-Tracker"
    },
    {
      title: 'Data center Downtime Tracker',
      description: 'Capture the minuet downtime and parallel tracking with jobs helps to optimise the data center.',
      image: "Datacenter-Downtime-Tracker"
    },
    {
      title: 'Auto Enabling Dead Servers',
      description: 'Let the servers treat themselves by continuous monitoring and running the scripts for maintenance in an Auto pilot mode.',
      image: "Auto-Enabling-Dead-Servers"
    },
    {
      title: 'AI Assist Manufacturing Unit',
      description: 'Run the entire Manufacturing unit, Monitor the run time and propose the maintenance of different units.',
      image: "AI-Assist-Manufacturing-Unit"
    },
    {
      title: 'Financial Document Classifications',
      description: 'Rule based machine learning decisions helps to classify the financial documents at ease.',
      image: "Financial-Document-Classifications"
    },
    {
      title: 'AI-Powered Logistics Management',
      description: 'Find the shortest routes, nearest vehicle, available places at ease to optimise the logistic solutions to scale up your Logistics management.',
      image: "AI-Powered-Logistics-Management"
    },
    {
      title: 'Taxi Aggregation Systems',
      description: 'Connect with the customers to provide on time, every time, multiple choice Taxi solutions by simplifying & automate your Internal process.',
      image: "Taxi-Aggregation-Systems"
    },
  ]
  return (
    <div className="caseStudies pageHeight">
      <Meta title='Case Studies' description='Our fully qualified team is here to help you to run your business with ease by implementing small technologies.' />

      <div className="pt-10 pb-2 xl:pb-3">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto">
            How do our <span className="folksRed">works</span> helping the people out there?
          </h1>
        </div>
        <div className="my-5">
          <div className="flex flex-wrap justify-evenly">
            {works.map(content => (
              <div className="bg-white caseStudies rounded-xl overflow-hidden shadow-md w-full xl:w-5/12 m-5">
                <div className="xl:flex">
                  <div className="xl:flex-shrink-0">
                    <ImgFile imageName={content.image} className="h-48 w-full object-cover xl:h-full xl:w-48" />
                  </div>
                  <div className="p-4">
                    <div className="block mt-1 text-md leading-tight font-medium text-black portTitle">{content.title}</div>
                    <p className="mt-2 text-gray-500">{content.description}</p>
                  </div>
                </div>
              </div>
            ))}

            <div className="bg-white rounded-xl overflow-hidden shadow-md w-full xl:w-5/12 m-5">
              <div className="xl:flex">
                <div className="xl:flex-shrink-0">
                  <ImgFile imageName="Case-Study" className="h-48 w-full object-cover xl:h-full xl:w-48" />
                </div>
                <div className="p-4">
                  <div className="block mt-1 text-md leading-tight font-medium text-black hover:underline">You have some other cases to solve?</div>
                  <p className="mt-2 text-gray-500">
                    Our fully qualified team is here to help you to run your business with ease by implementing small technologies.
                    <TalkToAConsultant /></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
