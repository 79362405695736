import { Meta } from "../common";
import { ImgFile } from "../common/image";

export const MissionVision = () => {
  return (
    <div className="about pageHeight">
      <Meta title='Mission & Vision' description='Together, shaping a world of possibilities.' />

      <div className="bgShapeSection hidden xl:block">
        <div className="bgShape bgShapeGray"></div>
        <div className="bgShape bgShapeBlack"></div>
        <div className="bgShape heroSubPageImage slide-up">
          <ImgFile imageName="mission" />
        </div>
      </div>

      <div className="pt-6 pb-2 px-5 xl:px-0 xl:w-6/12">
        <div className="mt-1 mb-6 xl:mt-12 xl:mb-6 slide-up delay-1">
          <span className="folksTitle text-5xl font-semibold">Mission & Vision</span>
          <h1 className="folksDesc text-2xl xl:text-2xl mx-auto">
            Our mission is to Empower
          </h1>
        </div>
        <div className="x-5 xl:px-0 slide-up delay-2">
          <p className="mb-3">At Together Corporation, our mission is simple: to empower growth and innovation through unity. We're committed to fostering an environment where diverse ideas converge, creating a brighter future for all."</p>
          <p className="mb-3">"Empowering Growth, Uniting Visions:</p>
          <p className="mb-3">Together, shaping a world of possibilities."</p>
          <p className="mb-3">Together Corporation assists people in growing and improving their lives.We aim to do this by working together as a team and bringing different ideas and perspectives together.This collaboration helps in building a future that is better and brighter for everyone.The company's focus is on supporting growth and unity to make a positive impact on the world.</p>
          <p className="mb-3">EST - 2009</p >
        </div>
      </div>

    </div>
  );
}
