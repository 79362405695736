import { Meta } from "../common";


export const CookiePolicy = () => {
  return (
    <div className="about pageHeight">
      <Meta title='Cookies Policy' description='Together Corporation' />

      <div className="pt-10 pb-2">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto">
            Cookies Policy
          </h1>
        </div>
        <div className="xl:px-0 px-10 ">
          <p className="p2">At Together Corporation, we value your privacy. Our website uses cookies to enhance user experience and provide personalized services. This Cookie Policy explains how and why we use cookies on our website.</p>
          <p className="p3">&nbsp;</p>
          <p className="p1 font-semibold">What Are Cookies?</p>
          <p className="p2">Cookies are small text files placed on your device (computer, tablet, mobile phone) when you visit our website. They help us improve your browsing experience by remembering your preferences, analyzing site traffic, and tailoring content to your interests.</p>
          <p className="p3">&nbsp;</p>
          <p className="p1 font-semibold">How We Use Cookies</p>
          <p className="p2"><span className="s1">Essential Cookies:</span> These cookies are necessary for the website's basic functionalities, such as navigation and access to secure areas. They do not store any personal information.</p>
          <p className="p3">&nbsp;</p>
          <p className="p2"><span className="s1">Analytical Cookies:</span> We use these cookies to gather information about how visitors use our website, including which pages are visited most often. This data helps us improve our site's performance and relevance.</p>
          <p className="p3">&nbsp;</p>
          <p className="p2"><span className="s1">Functional Cookies:</span> These cookies enable the website to remember choices you make (such as language preferences or region) to provide a more personalized experience.</p>
          <p className="p3">&nbsp;</p>
          <p className="p1 font-semibold">Your Cookie Choices</p>
          <p className="p2">You have the right to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify your browser settings to decline cookies if you prefer. Please note that disabling cookies may affect your experience on our website.</p>
          <p className="p3">&nbsp;</p>
          <p className="p1 font-semibold">Third-Party Cookies</p>
          <p className="p2">We may also use cookies from third-party services, such as Google Analytics, to track website usage and improve our services. These cookies are subject to the respective privacy policies of these external services.</p>
          <p className="p3">&nbsp;</p>
          <p className="p1 font-semibold">Updates to this Policy</p>
          <p className="p2">We may update our Cookie Policy from time to time to reflect changes in technology or legal requirements. Any updates will be posted on this page.</p>
          <p className="p3">&nbsp;</p>
          <p className="p1 font-semibold">Contact Us</p>
          <p className="p2">If you have any questions about our Cookie Policy or how we use cookies on our website, please contact us at corporate@togethercorporation.com</p>
          <p className="p3">&nbsp;</p>
        </div>

      </div>
    </div>
  );
}
