import { Link } from 'react-router-dom';
import '../styles/common.scss'
import { ImgFile } from './image';
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

export const Footer = () => {
  return (
    <div className='footer mt-20 w-full bg-black'>
      <div className="foot-section container mx-auto text-xs text-white py-8 px-5 xl:px-0">

        <div className='block xl:flex'>
          <div className='xl:w-1/3 xl:border-r border-b xl:border-b-0 pb-5 xl:pb-0 mb-5 xl:mb-0 border-slate-500'>
            <div className='flex justify-between xl:block'>
              <div>
                <ImgFile imageName='logo-white' width='120' />
                <div>Tour, Trend, Transform</div>
              </div>
              <div className='mt-5 flex'>
                <Link className='mb-1 mr-3' to="https://www.facebook.com/profile.php?id=100091496540756" target="_blank" rel="noreferrer"><FaFacebook size={24} color='#316FF6' /></Link>
                <Link className='mb-1' to="https://www.linkedin.com/company/together-edtech/" target="_blank" rel="noreferrer">
                  <FaLinkedin size={24} color='#0077B5' /></Link>
              </div>
            </div>
          </div>
          <div className='xl:w-1/2 xl:border-r border-b xl:border-b-0 border-slate-500 pb-5 xl:pb-0 mb-5 xl:mb-0 flex justify-between xl:justify-evenly'>
            <div className=''>
              <div className='text-xl mb-3'>Legal</div>
              <Link className='block hover:underline mb-1' to="/privacy-policy">Privacy Policy</Link>
              <Link className='block hover:underline mb-1' to="/terms-of-use">Terms & Condition</Link>
              <Link className='block hover:underline mb-1' to="/cookie-policy">Cookie Policy</Link>
              <Link className='block hover:underline mb-1' to="/disclaimer">Disclaimer</Link>
              <Link className='block hover:underline mb-1' to="/sustainability">Sustainability</Link>
            </div>
            <div className=''>
              <div className='text-xl mb-3'>Resources</div>
              <Link className='block hover:underline mb-1' to="https://blog.togethercorporation.com/">Blogs</Link>
              <Link className='block hover:underline mb-1' to="/faqs">FAQs</Link>
              <Link className='block hover:underline mb-1' to="/contact">Careers & Internships</Link>
              <Link className='block hover:underline mb-1' to="mailto:tours@togethercorporation.com?subject=Wanna Book a Tour" target='blank'>Booking & Reservations</Link>
              <Link className='block hover:underline mb-1' to="mailto:togethered@togethercorporation.com?subject=Lets Partner" target='blank'>Academic Collaborations</Link>
              <Link className='block hover:underline mb-1' to="mailto:corporate@togethercorporation.com?subject=Lets Partner" target='blank'>Industrial Partnerships</Link>
            </div>
          </div>
          <div className='xl:w-1/2 xl:pl-20'>
            <div className='text-xl mb-3'>Contact Us</div>
            <span className='block mb-4'>Corporate Headquarters  - Washington DC - USA</span>
            <span className='block text-base mb-3'>Offshore</span>
            <span className='block'>India</span>
            <span className='block'>Bangladesh</span>
            <span className='block'>Hongkong</span>
            <span className='block'>Singapore</span>
          </div>
        </div>

        <div className='mt-5 xl:mt-0'>&copy; {new Date().getFullYear()} Together Corporation USA.</div>
      </div>
    </div>
  );
}
