import React from 'react'
import { Helmet } from 'react-helmet'
import error from '../assets/images/shapes/404.svg'
import { Link } from 'react-router-dom'
import { ImgFile } from '../common/image'

export const Error404 = () => {
  return (
    <div className="error pageHeight">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sorry! 404</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <header className="App-header w-full h-full flex items-center justify-center">
        <img src={error} alt="" width="400" />
      </header>

      <div className="px-5 xl:px-0 xl:pt-10 mb-6">
        <span className="folksRed text-2xl">You've missed the route! Please pick one below</span>
      </div>
      <div className="py-10 flex serviceSection px-5 xl:px-0 xl:pt-1 flex-wrap">
        <Link to="/business/together-tourism" className="serviceBox tourism hover:shadow-lg hover:scale-110 slide-up">
          <div className="serviceBoxText leading-4">
            Together <br />Tourism
          </div>
          <div className="serviceBoxShape">
            <ImgFile imageName="tourism" width="60" />
          </div>
        </Link>

        <Link to="/business/togethered" className="serviceBox togethered hover:shadow-lg hover:scale-110 slide-up">
          <div className="serviceBoxText leading-4">
            TogetherEd
          </div>
          <div className="serviceBoxShape">
            <ImgFile imageName="tech" width="60" />
          </div>
        </Link>

        <Link to="/business/together-threads" className="serviceBox fashion hover:shadow-lg hover:scale-110 slide-up">
          <div className="serviceBoxText leading-4">
            Together<br />
            Threads
          </div>
          <div className="serviceBoxShape">
            <ImgFile imageName="fashion" width="60" />
          </div>
        </Link>
      </div>
    </div>
  )
}
