import { Home, Privacy, Terms, Contact, TogetherThreads, TogetherTourism, Togethered, CookiePolicy, Disclaimer, FAQs, Sustainability, OurStory, MissionVision, OurCoreValues, Error404 } from '../pages';
import { PhoneIcon, PlayCircleIcon, GlobeAsiaAustraliaIcon } from '@heroicons/react/20/solid'
import { PiShirtFoldedDuotone } from "react-icons/pi";
import { CgSmartphoneChip } from "react-icons/cg";

import {
  FingerPrintIcon, FireIcon, GlobeAltIcon, ChatBubbleBottomCenterIcon
} from '@heroicons/react/24/outline'
import { CaseStudies } from '../pages/case-studies';

export const RoutesLinks = {
  essMenu: [
    { path: '*', name: '404', element: <Error404 /> },
    { path: '/', name: 'Home', element: <Home /> },
    { path: '/about/our-story', name: 'Our Story', element: <OurStory /> },
    { path: '/about/mission-vision', name: 'Mission & Vision', element: <MissionVision /> },
    { path: '/about/our-core-values', name: 'Our Core Values', element: <OurCoreValues /> },
    { path: '/contact', name: 'Contact', element: <Contact /> },
    { path: '/business/togethered/case-studies', name: 'Case Studies', element: <CaseStudies /> }
  ],
  miscMenu: [
    { path: '/faqs', name: 'FAQs', element: <FAQs /> },
    { path: '/sustainability', name: 'Sustainability', element: <Sustainability /> },
    { path: '/terms-of-use', name: 'Terms of Use', element: <Terms /> },
    { path: '/cookie-policy', name: 'Cookie Policy', element: <CookiePolicy /> },
    { path: '/disclaimer', name: 'Disclaimer', element: <Disclaimer /> },
    { path: '/privacy-policy', name: 'Privacy Policy', element: <Privacy /> }
  ],
  buzMenu: [
    { path: '/business/together-tourism', name: 'Together Tourism', element: <TogetherTourism /> },
    { path: '/business/togethered', name: 'TogetherEd', element: <Togethered /> },
    { path: '/business/together-threads', name: 'Together Threads', element: <TogetherThreads /> },
  ]
}

export const combinedRouters = [...RoutesLinks.essMenu, ...RoutesLinks.buzMenu, ...RoutesLinks.miscMenu];


export const HomeLink = [
  { name: 'Home', description: 'Together Corporation Home', url: '/', icon: ChatBubbleBottomCenterIcon }
]

export const MiscLinks = [
  { url: '/sustainability', name: 'Sustainability', description: '' },
  { url: '/faqs', name: 'FAQs', description: '' },
]

export const contactDropdown = [
  { name: 'Contact', description: 'Not to the Bots. Talk like buddy', url: '/contact', icon: ChatBubbleBottomCenterIcon },
  { name: 'Case Studies', description: 'Not to the Bots. Talk like buddy', url: '/business/togethered/case-studies', icon: ChatBubbleBottomCenterIcon }
]

export const aboutDropdown = [
  { name: 'Our Story', description: 'Get a better understanding of your traffic', url: '/about/our-story', icon: FireIcon },
  { name: 'Mission & Vision', description: 'Speak directly to your customers', url: '/about/mission-vision', icon: GlobeAltIcon },
  { name: 'Our Core Values', description: "Leading through action", url: '/about/our-core-values', icon: FingerPrintIcon }
]

export const businessDropdown = [
  { name: 'Together Tourism', description: 'Connect to the World', url: '/business/together-tourism', icon: GlobeAsiaAustraliaIcon },
  { name: 'TogetherEd', description: 'Empower to change', url: '/business/togethered', icon: CgSmartphoneChip },
  { name: 'Together Threads', description: 'Fashion at its best', url: '/business/together-threads', icon: PiShirtFoldedDuotone }
]

export const callsToAction = [
  { name: 'Watch demo', url: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', url: '#', icon: PhoneIcon },
]


export const hamBurgerMinks = [aboutDropdown[0], ...businessDropdown, ...contactDropdown]