import { NavLink } from "react-router-dom";
import { Meta } from "../common";
import { ImgFile } from "../common/image";


export const TogetherThreads = () => {
  const works = [
    {
      title: "Elegance - Woman's Lifestyle",
      description: "Stylish, sustainable, sophisticated, for the modern woman",
      image: "port1",
      link: 'https://threads.togethercorporation.com/'
    },
    {
      title: "Edge - Men's Lifestyle",
      description: "Urban style, effortlessly cool, comfortable for the contemporary man.",
      image: "port2",
      link: 'https://threads.togethercorporation.com/'
    },
    {
      title: "Elysium - Kid's Lifestyle",
      description: "Playful, durable, vibrant, and comfy for little adventurers",
      image: "port3",
      link: 'https://threads.togethercorporation.com/'
    },
    {
      title: "Emerge - New Born's Lifestyle",
      description: "Soft, subtle , warm designed to cradle the little ones",
      image: "port4",
      link: 'https://threads.togethercorporation.com/'
    },
    {
      title: "Eternal - Senior's Lifestyle",
      description: "Simple, comfortable, enduring curated with care for 60+",
      image: "port5",
      link: 'https://threads.togethercorporation.com/'
    },
    {
      title: "Essence - Soft Furnishings",
      description: "A harmonious blend of design, comfort, and aesthetics with thoughtful detail on living space",
      image: "port6",
      link: 'https://threads.togethercorporation.com/'
    }
  ]
  const clients = ['pc1', 'pc2', 'pc3', 'pc4', 'pc5'];
  return (
    <div className="about pageHeight">
      <Meta title='Together Threads' description='Together Threads: Where sustainable style meets innovation, shaping fashion with a conscience.' />

      <div className="pt-10 pb-2 px-6 xl:px-0">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-8 ">
          {/* <span className="folksRed slide-up delay-1">Finding Inspiration in Every Thread</span> */}
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto slide-up delay-1">
            Together <span className="folksRed">Threads</span>
          </h1>
          <div className="pt-5 slide-up delay-1">
            Founded on the principles of collaboration and innovation, Together Threads emerged from a passion for connecting quality apparel manufacturers with small-scale buyers and online marketplaces. Our journey began with a vision to revolutionize the import-export dynamics in the fashion industry by emphasizing quality, sustainability, and personalized service.
          </div>

          <div className='flex justify-center'>
            <NavLink className={'mx-5 xl:mx-0 slide-up py-2 cursor-pointer px-4 w-56 text-center text-white font-semibold rounded mt-4 consultButton'} to={'https://calendly.com/togetheredtech'} target="blank">Get Started</NavLink>
          </div>
        </div>

        <div className="bg-gradient-to-r from-slate-600 to-slate-900 slide-up delay-2">
          <ImgFile imageName="threadshead" />
        </div>
        <div className="text-center mb-6 mt-12 xl:mb-6 xl:w-4/5 mx-auto">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 folksRed xl:w-6/12 mx-auto slide-up delay-2">Trending</h1>
          <div className="pt-5 slide-up delay-2">
            <p className="mb-3">We began with a simple idea: to connect great clothing makers with small buyers and online shops. Our goal was clear - to make quality, sustainable fashion accessible and affordable for everyone.</p>

            <p className="mb-3">Starting from a passion for fair trade and a love for fashion, Together Threads was born. We set out to create something different - a place where boutique owners, emerging brands, and online retailers could find top-notch clothing without compromising on ethics or style.</p>

            <p className="mb-3">From day one, honesty, fairness, and responsibility have guided us. We believed in doing things right - sourcing from ethical manufacturers and prioritizing eco-friendly practices.</p>

            <p className="mb-3">Growing alongside changing trends, we embraced the shift to online markets and the demand for sustainable, customizable fashion. We constantly adapt to offer the latest styles while staying true to our commitment to sustainability.</p>

            <p className="mb-3">Our mission remains unchanged - to build lasting relationships with our clients.We're here not just to sell clothes but to support our partners' growth and success.</p >

            <p className="mb-3">As we look forward, Together Threads is committed to innovation, sustainability, and making our mark in the apparel world.Our story is about passion, dedication, and our journey towards a better, more collaborative fashion industry.</p >
          </div >
        </div >
        <div className="text-center mt-12 px-5 xl:px-0">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 folksRed xl:w-6/12 mx-auto slide-up delay-2">Portfolio</h1>
          <div className="flex flex-wrap justify-evenly">
            {works.map((content, index) => (
              <NavLink to={content.link} target="blank" className={`bg-white border rounded-xl overflow-hidden hover:shadow-lg w-full lg:w-5/12 m-5 slide-up grayscale hover:grayscale-0 transition-all cursor-pointer hover:bg-slate-100 delay-${index}`}>
                <div className="xl:flex lg:flex">
                  <div className="lg:flex-shrink-0">
                    <ImgFile imageName={content.image} className="h-48 w-full object-cover xl:h-full xl:w-48" />
                  </div>
                  <div className="p-4">
                    <div className="block mt-1 text-md leading-tight font-medium text-black hover:underline portTitle">{content.title}</div>
                    <p className="mt-2 text-gray-700">{content.description}</p>
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        </div>

        <div className="text-center mt-12 px-5 xl:px-0">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 folksRed xl:w-6/12 mx-auto slide-up delay-2">Our Clients</h1>
          <div className="my-5 p-4 xl:p-10">
            <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {clients.map((item, index) => <div className="grid place-content-center" key={index}>
                <ImgFile imageName={item} width="120px" />
              </div>)}
            </div>
          </div>
        </div>

      </div >
    </div >
  );
}
