import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface MetaProps {
  title: string;
  description: string;
  url?: string;
}

export const Meta = (props: MetaProps) => {
  const location = useLocation()
  const path: string = location.pathname;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta
        name="description"
        content={props.description}
      />
      <link rel="canonical" href={`https://www.togethercorporation.com/${props.url ? props.url : path}`} />
    </Helmet>
  );
}
