import { Meta } from "../common";
import video1 from '../assets/images/togetherRight/video1.mp4'
import video2 from '../assets/images/togetherRight/video2.mp4'
import { Link, NavLink } from "react-router-dom";


export const TogetherTourism = () => {
  return (
    <div className="about pageHeight">
      <Meta title='Together Tourism' description='Explore. Learn.Experience.  Together Tourism: Crafting educational journeys, igniting curiosity globally.' />

      <div className="pt-10 pb-2 px-6 xl:px-0">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto slide-up delay-1">
            Together <span className="folksRed">Tourism</span>
          </h1>
        </div>
        <div className="text-center slide-up delay-2">
          <p className="mb-3">For over a decade, we've been at the forefront of revolutionizing Edu-Tours and Industrial Visits, pioneering innovative learning experiences that seamlessly blend academia with real-world industry insights. From inception, we've embraced cutting-edge approaches to education, offering transformative journeys for students and corporate groups alike.</p>
        </div>
        <div className="text-center slide-up delay-2">
          <Link to={"https://online.fliphtml5.com/jbuon/geyn/"} target="blank" className="mx-5 xl:mx-0 slide-up py-2 cursor-pointer px-4 w-40 text-center text-white font-semibold rounded mt-4 consultButton">
            Let's go!
          </Link>
        </div>
        <div className="my-5 p-4 xl:p-10">
          <div className="clientSecs justify-evenly">
            <div className="bg-white flex flex-wrap xl:flex-nowrap rounded overflow-hidden slide-up delay-3">
              <div className="xl:w-1/2 p-5">
                <div className="folksTitle font-bold text-3xl pb-5">Why Choose Us?</div>
                <div>
                  <ul className="list-outside list-disc ml-5">
                    <li className="pb-3"><strong>A Decade of Expertise:</strong> With over twelve years of industry expertise, our journey has been about redefining education through immersive experiences that align with the evolving industrial landscape.</li>
                    <li className="pb-3"><strong>Pioneering Educational Models:</strong> Since 2009, we've continuously innovated, developing educational models that merge academic theory with practical industrial exposure, preparing individuals for the future.</li>
                    <li className="pb-3"><strong>Established Industry Connections:</strong> Over the years, we've cultivated strong relationships, securing unparalleled access to major industries, ensuring our tours deliver authentic and unparalleled insights.</li>
                  </ul>
                </div>
              </div>
              <div className="xl:w-1/2">
                <video controls={false} autoPlay={true} src={video1} loop={true} muted={true} webkit-playsinline playsInline />
              </div>
            </div>
            <div className="bg-white flex flex-wrap xl:flex-nowrap flex-col-reverse xl:flex-row rounded overflow-hidden slide-up delay-4">
              <div className="xl:w-1/2">
                <video controls={false} autoPlay={true} src={video2} loop={true} muted={true} webkit-playsinline playsInline />
              </div>
              <div className="xl:w-1/2 p-5">
                <div className="folksTitle font-bold text-3xl pb-5">Our Offerings</div>
                <div>
                  <ul className="list-outside list-disc ml-5">
                    <li className="pb-3"><strong>Decade-Crafted Tours:</strong> Our tours reflect a wealth of experience, meticulously curated to provide the most up-to-date industry insights and academic integrations.</li>
                    <li className="pb-3"><strong>Legacy of Learning:</strong> Building on our decade-long legacy, our programs offer a comprehensive view of industries, equipping participants with future-ready skills and knowledge.</li>
                    <li className="pb-3"><strong>Global Reach:</strong> Extending our legacy globally, we continue to explore and incorporate international industrial and academic dimensions into our tours.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center px-5 xl:px-0 slide-up delay-5">
          <p className="mb-3">Embark on a journey that transforms education into an adventure! Connect with us today to craft an experiential learning expedition or an industry visit that inspires innovation and unlocks boundless opportunities for your group.</p>

          <p className="mb-3">Let's redefine learning together! Contact us now to start your transformative educational experience.</p>
        </div>
        <div className='flex justify-center'>
          <NavLink className={'mx-5 xl:mx-0 slide-up py-2 cursor-pointer px-4 w-56 text-center text-white font-semibold rounded mt-4 consultButton'} to={'https://forms.gle/Zs13qe9TeTLRFgqq5'} target="blank">Get Started</NavLink>
        </div>

      </div>
    </div>
  );
}
