import React, { useState } from 'react';
import { Meta } from '../common';
import { ContactForm } from '../common/contact-form';
import { TfiClose } from 'react-icons/tfi';
import { CareerInternshioForm } from '../common/careers-internship-form';
import { ImgFile } from '../common/image';

export const Contact = () => {

  const [variable, setVariable] = useState(false);
  const handleClick = () => {
    setVariable(!variable);
  }

  return (
    <>
      <div className="about pageHeight">
        <Meta title='Contact Us' description="Get in touch with Together Corporation. Your questions, our answers. Let's connect and collaborate for a brighter future." />

        <div className="bgShapeSection hidden xl:block">
          <div className="bgShape bgShapeGray"></div>
          <div className="bgShape bgShapeBlack"></div>
          <div className="bgShape heroSubPageImage slide-up">
            <ImgFile imageName="contactus" />
          </div>
        </div>

        <div className="pt-6 pb-2 px-5 xl:px-0 xl:w-6/12">
          <div className="mt-1 mb-6 xl:mt-12 xl:mb-6 slide-up delay-1">
            <span className="folksTitle text-5xl font-semibold">Contact Us</span>
          </div>
          <div className="x-5 xl:px-0 slide-up delay-2">
            <div className='flex flex-col justify-center items-center mb-14'>
              <ContactForm className='bg-white border shadow-xl rounded p-5 w-full' />
            </div>

            <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-2">
              <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 mx-auto">
                Ready to work <span className="folksRed">TOGETHER!</span>.
              </h1>
            </div>
            <div className="my-5 xl:p-2 text-center w-10/12 mx-auto">
              <p className='mb-3'>At Together Corp, we come together to address challenges in a way for our clients across a variety of capabilities and industries. We create powerful solutions to make a positive impact, Every day.</p>

              <p className='mb-3'>Are you ready to make a difference?</p>

              <p className='mb-3'>Let's get to work. Find your career at Together Corp by just submitting your form here.</p>
            </div>
          </div>

          <div className='flex justify-center'>
            <div onClick={handleClick} className="mx-5 xl:mx-0 slide-up py-2 cursor-pointer px-4 w-56 text-center text-white font-semibold rounded mt-4 consultButton">
              Careers & Internship
            </div>
          </div>
        </div>


        <div className={(variable ? 'opened' : 'closed') + ' navigationPane contact overflow-y-auto'}>
          <div className="text-white cursor-pointer closeIco" onClick={handleClick}>
            <TfiClose color='#000' size={'2rem'} />
          </div>

          <CareerInternshioForm className="w-full pr-5 mb-20" />
        </div>

      </div>
    </>
  );
}
