import { useState } from 'react';
import '../styles/common.scss'
import { TfiClose } from 'react-icons/tfi';
import { ContactForm } from './contact-form';


export const TalkToAConsultant = () => {
  const [variable, setVariable] = useState(false);
  const handleClick = () => {
    setVariable(!variable);
  }
  return (
    <div>
      <div onClick={handleClick} className="mx-5 xl:mx-0 slide-up py-2 cursor-pointer px-4 w-40 text-center text-white font-semibold rounded mt-4 consultButton">
        Connect
      </div>

      <div className={(variable ? 'opened' : 'closed') + ' navigationPane contact'}>
        <div className="text-white cursor-pointer closeIco" onClick={handleClick}>
          <TfiClose color='#000' size={'2rem'} />
        </div>

        <ContactForm className='mr-5' />
      </div>
    </div>
  );
}
