import { Meta } from "../common";


export const Privacy = () => {
  return (
    <div className="privacy pageHeight">
      <Meta title='Privacy Policy' description='Together Corporation' />

      <div className="pt-10 pb-2">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto">
            Privacy Policy
          </h1>
        </div>
        <div className="xl:px-0 px-10 ">
          <p className="p1">Website Privacy Policy updated 01 January, 2024.</p>
          <p className="p2">&nbsp;</p>
          <p className="font-bold">Introduction</p>
          <p className="p4">Together Corporation (&ldquo;Corporation&rdquo;&nbsp;or&nbsp;&ldquo;we&rdquo;) respects your privacy and is committed to protecting it through our compliance with this policy.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">This policy describes the types of information we may collect from you or that you may provide when you visit the website togethercorporation.com (our&nbsp;&ldquo;Website&rdquo;) and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">This policy applies to the information we collect on this website.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">In an email, text, and other electronic messages between you and this Website.</p>
          <p className="p4">When you interact with our advertising and applications on third-party websites and services the applications or advertising materials include links to this policy.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">It does not apply to information collected by:</p>
          <p className="p4">Us offline or through any other means, including on any other website operated by the Company or any third party (including our affiliates and subsidiaries); or</p>
          <p className="p4">Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website.</p>
          <p className="p4">This website and our services are designed for representatives of academic institutions, industries, and small businesses and are not intended for personal or household use. Accordingly, we treat all personal information covered by this Privacy Policy, including information about any visitors to our website, as individuals acting as business representatives, rather than in their capacity.</p>
          <p className="p4">Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our website. By accessing or using this website, you agree to this privacy policy. This policy may change from time to time. Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
          <p className="p5">&nbsp;</p>
          <p className="font-bold">Children Under the Age of 16</p>
          <p className="p4">Our website is not intended for children under 16 years of age. No one under the age of 16 may provide any personal information to or on the Website. We do not knowingly collect personal information from children under 16. If you are under 16, do not use or provide any information on this Website or through any of its features, register on the Website, use any of the interactive or public comment features of this Website or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or username you may use. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at corporate@togethercorporation.com</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information.</p>
          <p className="p5">&nbsp;</p>
          <p className="font-bold">Information We Collect About You and How We Collect It</p>
          <p className="p4">We collect several types of information from and about users of our website, including information:</p>
          <p className="p4">By which you may be personally identified, such as name, postal address, e-mail address, telephone number, or any other identifier by which you may be contacted online or offline (&ldquo;personal information&rdquo;).</p>
          <p className="p4">That is about you but individually does not identify you, such as details of your visits to our website.</p>
          <p className="p4">About your internet connection, the equipment you use to access our website, and usage details.</p>
          <p className="p4">We collect this information:</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">Directly from you when you provide it to us.</p>
          <p className="p4">Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</p>
          <p className="p4">From third parties, for example, our business partners.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4"><em>Information You Provide to Us</em></p>
          <p className="p4">The information we collect on or through our website may include:</p>
          <p className="p4">Information that you provide by filling in forms on our website. This includes information provided at the time of registering to use our website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our website.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">Records and copies of your correspondence (including email addresses), if you contact us.</p>
          <p className="p4">Your responses to surveys that we might ask you to complete for research purposes.</p>
          <p className="p4">Your search queries on the website.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">You also may provide information to be published or displayed (hereinafter,&nbsp;&ldquo;posted&rdquo;) on public areas of the website or transmitted to other users of the Website or third parties (collectively,&nbsp;&ldquo;User Contributions&rdquo;). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages, and you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized</p>
          <p className="p4">persons.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4"><em>Information We Collect Through Automatic Data Collection Technologies</em></p>
          <p className="p4">As you navigate through and interact with our website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
          <p className="p4">Details of your visits to our website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">Information about your computer and internet connection, including your IP address, operating system, and browser type.</p>
          <p className="p4">The information we collect automatically is only statistical data and does not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our website and to deliver a better and more personalized service, including by enabling us to: Estimate our audience size and usage patterns.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">Store information about your preferences, allowing us to customize our website according to your interests.</p>
          <p className="p4">Speed up your searches.</p>
          <p className="p4">Recognize you when you return to our website.</p>
          <p className="p4">The technologies we use for this automatic data collection may include:</p>
          <p className="p4">Cookies (or browser cookies).&nbsp;A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser Cookies by activating the appropriate setting on your browser. However, if you select this setting, you may be unable to access certain parts of our website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our website.</p>
          <p className="p4">Flash Cookies.&nbsp;Certain features of our website may use locally stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.</p>
          <p className="p4">Web Beacons.&nbsp;Pages of our Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</p>
          <p className="p4">We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or that you provide to us.</p>
          <p className="p5">&nbsp;</p>
          <p className="font-bold">How We Use Your Information</p>
          <p className="p4">We use information that we collect about you or that you provide to us, including any personal information:</p>
          <p className="p4">To present our website and its contents to you.</p>
          <p className="p4">To provide you with information, products, or services that you request from us.</p>
          <p className="p4">To fulfil any other purpose for which you provide it.</p>
          <p className="p4">To provide you with notices about your account.</p>
          <p className="p4">To carry out our obligations and enforce our rights arising from any contracts entered between you and us, including for billing and collection.</p>
          <p className="p4">To notify you about changes to our website or any products or services we offer or provide through it.</p>
          <p className="p4">To allow you to participate in interactive features on our website.</p>
          <p className="p4">In any other way, we may describe when you provide the information.</p>
          <p className="p4">For any other purpose with your consent.</p>
          <p className="font-bold">Disclosure of Your Information</p>
          <p className="p4">We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>
          <p className="p4">We may disclose personal information that we collect, or you provide as described in this privacy policy:</p>
          <p className="p4">To our subsidiaries and affiliates.</p>
          <p className="p4">To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</p>
          <p className="p4">To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all Together EdTech&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Together EdTech about our Website users is among the assets transferred.</p>
          <p className="p4">To fulfill the purpose for which you provide it. For example, if you give us an email address to use the &ldquo;email a friend&rdquo; feature of our website, we will transmit the contents of that email and your email address to the recipients.</p>
          <p className="p4">For any other purpose disclosed by us when you provide the information.</p>
          <p className="p4">With your consent.</p>
          <p className="p4">We may also disclose your personal information:</p>
          <p className="p4">To comply with any court order, law, or legal process, including responding to any government or regulatory request.</p>
          <p className="p4">To enforce or apply our terms of use at&nbsp;<span className="s1">togethercorporation.com/terms-of-use</span>/&nbsp;and other agreements, including for billing and collection purposes.</p>
          <p className="p4">If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Academic Partnerships, our customers, or others. This includes exchanging information with other companies and organizations for fraud protection.</p>
          <p className="p5">&nbsp;</p>
          <p className="font-bold">Choices About How We Use and Disclose Your Information</p>
          <p className="p4">We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
          <p className="p4">Tracking Technologies and Advertising.&nbsp;You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe&rsquo;s website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">We do not control third parties' collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&ldquo;NAI&rdquo;) on the NAI&rsquo;s website.</p>
          <p className="p5">&nbsp;</p>
          <p className="font-bold">Accessing and Correcting Your Information</p>
          <p className="p4">To review and change your personal information you may send us an email at&nbsp;<span className="s2">corporate@togethercorporation.com</span>&nbsp;to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
          <p className="p5">&nbsp;</p>
          <p className="p4">If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages or might have been copied or stored by other Website users. Proper access and use of information provided on the Website, including User Contributions, is governed by our terms of use at&nbsp;<span className="s2">togethercorporation.com/terms-of-use/</span>.</p>
          <p className="p4">California residents may have additional personal information rights and choices. Please see Your California Privacy Rights for more information.</p>
          <p className="p5">&nbsp;</p>
          <p className="font-bold">Data Security</p>
          <p className="p4">We have implemented measures designed to secure your personal information from accidental loss and unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Personal information will be encrypted using SSL technology.</p>
          <p className="p4">The safety and security of your information also depend on you. Where we have given you (or where you have chosen) a password for access to certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website.</p>
          <p className="p4">Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our website. Any transmission of personal information is at your own risk. We are not responsible for the circumvention of any privacy settings or security measures contained on the Website.</p>
          <p className="p5">&nbsp;</p>
          <p className="font-bold">Changes to Our Privacy Policy</p>
          <p className="p4">It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users&rsquo; personal information, we will notify you through a notice on the Website home page and this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting our website and this privacy policy to check for any changes.</p>
          <p className="p5">&nbsp;</p>
          <p className="font-bold">Contact Information</p>
          <p className="p4">To ask questions or comment about this privacy policy and our privacy practices, contact us at:<br /><span className="s2"><strong>corporate@togethercorporation.com</strong></span></p>
          <p className="p4">To report a security issue or discovered vulnerability, please email <span className="s2"><strong>corporate@togethercorporation.com</strong></span>with a description of the issue, the steps you took to create the issue, and if known, mitigations for the issue. Our vulnerability management team will review all reports and acknowledge receiving your email.</p>
          <p className="p5">&nbsp;</p>
        </div>

      </div>
    </div>
  );
}
