import { Meta } from "../common";

export const Disclaimer = () => {
  return (
    <div className="about pageHeight">
      <Meta title='Disclaimer' description='Together Corporation' />

      <div className="pt-10 pb-2">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto">
            Disclaimer
          </h1>
        </div>
        <div className="xl:px-0 px-10 ">
          <p className="p2">The information provided on the Together Corporation website is for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
          <p className="font-bold mt-5">Professional Advice</p>
          <p className="p2">The content on this website does not constitute professional advice. Any actions you take based on the information provided on this website are strictly at your own risk. We recommend seeking professional advice or assistance where appropriate.</p>
          <p className="font-bold mt-5">External Links</p>
          <p className="p2">Our website may contain links to external websites that are not maintained or controlled by Together Corporation. We do not endorse the content or have control over the nature, content, and availability of these external sites. The inclusion of any links does not necessarily imply a recommendation or endorsement of the views expressed within them.</p>
          <p className="font-bold mt-5">Limitation of Liability</p>
          <p className="p2">In no event will Together Corporation be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
          <p className="font-bold mt-5">Updates to this Disclaimer</p>
          <p className="p2">Together Corporation reserves the right to update or change this disclaimer at any time without prior notice. Any changes will be effective immediately upon posting on this page.</p>
          <p className="p3">&nbsp;</p>
        </div>

      </div>
    </div>
  );
}
