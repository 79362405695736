import '../styles/home.scss'
import { Link } from 'react-router-dom'
import { Meta, TalkToAConsultant } from '../common'
import { ImgFile } from '../common/image';
import Slide from '../misc/slider';

export const Home = () => {
  return (
    <div className="home pageHeight">
      <Meta title='Together Corporation | Empowering Minds, Transforming Style, Enriching Journeys: Together, Creating a Brighter Future.' description='Welcome to Together Corporation, where innovation meets impact. Join us in shaping a brighter future through collaborative solutions.' />

      <div className="bgShapeSection hidden lg:block">
        <div className="bgShape bgShapeGray"></div>
        <div className="bgShape bgShapeBlack"></div>
        <div className="bgShape slide-up"></div>
        <Slide />
      </div>

      <div className="pb-2">
        <div className="py-10">
          <div className="px-5 xl:px-0 xl:pt-10 font-black text-4xl xl:text-5xl heroText">
            <div className="block slide-up lg:w-6/12 xl:w-8/12">
              <p>"Empowering Minds, Transforming Style, and Enriching Journeys: <span className='folksRed'>Together</span>, we create a brighter future."</p>
            </div>
            <div className="slide-up text-2xl xl:text-2xl">
              <p>The destination for leaders who seek to change the world</p>
            </div>
          </div>
          <div className=''>
            <TalkToAConsultant />
          </div>
        </div>

        <div className="pt-10 pb-2 flex serviceSection px-5 xl:px-0 xl:pt-1 flex-wrap">
          <Link to="/business/together-tourism" className="serviceBox tourism hover:shadow-lg hover:scale-110 slide-up">
            <div className="serviceBoxText leading-4">
              Together <br />Tourism
            </div>
            <div className="serviceBoxShape">
              <ImgFile imageName="tourism" width="60" />
            </div>
          </Link>

          <Link to="/business/togethered" className="serviceBox togethered hover:shadow-lg hover:scale-110 slide-up">
            <div className="serviceBoxText leading-4">
              TogetherEd
            </div>
            <div className="serviceBoxShape">
              <ImgFile imageName="tech" width="60" />
            </div>
          </Link>

          <Link to="/business/together-threads" className="serviceBox fashion hover:shadow-lg hover:scale-110 slide-up">
            <div className="serviceBoxText leading-4">
              Together<br />
              Threads
            </div>
            <div className="serviceBoxShape">
              <ImgFile imageName="fashion" width="60" />
            </div>
          </Link>
        </div>



        <div className="pt-5 mt-5 xl:mt-0 grid grid-cols-2 xl:grid-cols-4 gap-1 px-5 xl:px-0 xl:pt-10 xl:w-6/12">
          <div className="experienceBox slide-up">
            <div className="experienceBoxCount leading-8">
              10+
            </div>
            <div className="experienceBoxDetail">
              Years in Business
            </div>
          </div>
          <div className="experienceBox slide-up">
            <div className="experienceBoxCount leading-8">
              100+
            </div>
            <div className="experienceBoxDetail">
              Industry Leading Mentors
            </div>
          </div>
          <div className="experienceBox slide-up">
            <div className="experienceBoxCount leading-8">
              1000+
            </div>
            <div className="experienceBoxDetail">
              Global Connects
            </div>
          </div>
          <div className="experienceBox slide-up">
            <div className="experienceBoxCount leading-8">
              100000+
            </div>
            <div className="experienceBoxDetail">
              Satisfaction Rated by Students
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
