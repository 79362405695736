/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { ImgFile } from '../common/image';
import "./../app.scss";

const Slide = () => {
  const slideRef = useRef<HTMLDivElement>(null);
  let currentSlide = 0;
  let prevSlide = 0;

  useEffect(() => {
    const slide = slideRef?.current;
    if (slide) {
      const arr = Array.from(slide?.children) as HTMLImageElement[];

      arr?.forEach((imgObj) => {
        imgObj?.classList?.add("slide-img");
      });

      arr[currentSlide]?.classList.add("show");

      setInterval(() => {
        if (currentSlide >= arr?.length) {
          currentSlide = 0;
        }

        arr[prevSlide]?.classList?.remove("show");
        arr[currentSlide]?.classList?.add("show");

        prevSlide = currentSlide;

        currentSlide++;

      }, 5000);
    }
  }, []);

  return (
    <div id="slide" ref={slideRef}>
      <ImgFile imageName="heroPeople1" />
      <ImgFile imageName="heroPeople2" />
      <ImgFile imageName="heroPeople3" />
      <ImgFile imageName="heroPeople4" />
      <ImgFile imageName="heroPeople5" />
      <ImgFile imageName="heroPeople6" />
    </div>
  );
};

export default Slide;