import React from "react";

interface ImageProps {
  imageName: string;
  width?: string;
  height?: string;
  className?: string;
}

export const ImgFile = (props: ImageProps) => {
  const images = require.context('../assets', true, /\.(png|jpe?g|svg|gif)$/);
  const imageList = images.keys().map((image) => images(image));

  const importAll = (imagePath: string[]) => {
    let img: {
      imgName: string | undefined;
      url: string;
    }[] = [];
    imagePath.forEach((item, index) => {
      const imageNamewithEx = item.split('/').pop();
      const imageName = imageNamewithEx?.split('.').shift();
      const imageObj = { imgName: imageName, url: item };
      img.push(imageObj);
    });
    return img;
  };

  const assetList = importAll(imageList);
  const extractSpecificImage = assetList?.find(item => item.imgName === props.imageName);


  return (
    <img src={extractSpecificImage?.url} alt={extractSpecificImage?.imgName} width={props.width} height={props.height} className={props.className} />
  );
}