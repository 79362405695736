import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from "react";
import { NavLink } from 'react-router-dom';

interface DropDownProps {
  menuName: string;
  menuList: MenuList[];
  callAction?: CallAction[];
  className?: string;
  activeClass?: string;
  arrowRequired?: boolean
}

interface MenuList {
  name: string;
  url: string;
  description?: string;
  icon?: any;
}

interface CallAction {
  name: string;
  url: string;
  icon?: any;
}

export const DropDown = (props: DropDownProps) => {
  let { menuList, callAction } = props;
  return (
    <Popover className={`relative ${props.className}`}>
      <Popover.Button className="inline-flex items-center gap-x-1 text-base transition-all leading-6 focus-visible:outline-none menuHover">
        <span>{props.menuName}</span>
        {props.arrowRequired ? <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> : ''}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-80 max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {menuList.map((item) => (
                <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                  <div className="mt-1 flex h-5 w-6 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                  </div>
                  <div>
                    <NavLink to={item.url} className={({ isActive }) => isActive ? `font-semibold text-gray-900 ${props?.activeClass}` : 'font-semibold text-gray-900'}>
                      {item.name}
                      <span className="absolute inset-0" />
                    </NavLink>
                    {/* <p className="mt-1 text-gray-600">{item.description}</p> */}
                  </div>
                </div>
              ))}
            </div>
            {props?.callAction?.length ? <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              {callAction?.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.url}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  {item.name}
                </NavLink>
              ))}
            </div> : ''}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>

  );
}
