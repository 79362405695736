import React, { useLayoutEffect } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import "./app.scss";
import { Footer, Header, combinedRouters } from './common';
import CookieConsent from "react-cookie-consent";


export const App = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo({
      left: 0, top: 0, behavior: 'smooth'
    })
  }, [pathname])

  return (
    <div className='w-full mb-100'>
      <Header />
      <div className="container mx-auto">
        <Routes>
          {
            combinedRouters.map((item, index) => <Route key={index} path={item.path} element={item.element} />)
          }
        </Routes>
      </div>
      <Footer />
      <CookieConsent enableDeclineButton flipButtons
        location="bottom"
        buttonText="I accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "transparent" }}
        containerClasses='cookieContainer shadow-md'
        buttonClasses='cookieAcceptButton'
        declineButtonClasses='cookieDeclineButton'
        expires={150}
      >
        We use cookies to improve your browsing experience. By continuing to use our site, you consent to the use of cookies. Learn more in our <Link className='linksDefault' to={'/cookie-policy'}>Cookie Policy</Link>.
      </CookieConsent>
    </div>
  );
}