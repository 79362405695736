import { Meta } from "../common";


export const Sustainability = () => {
  return (
    <div className="about pageHeight">
      <Meta title='Our Commitment to Sustainability' description='Together Corporation' />

      <div className="pt-10 pb-2">
        <div className="text-center mt-1 mb-6 xl:mt-12 xl:mb-6">
          <h1 className="text-3xl xl:text-5xl HelFontBlack w-10/12 xl:w-6/12 mx-auto">
            Our Commitment to Sustainability
          </h1>
        </div>
        <div className="px-6 xl:px-0">
          <p>At Together Corporation, sustainability isn't just a goal; it's a fundamental principle woven into our business operations. We are dedicated to making a positive impact on the environment, communities, and industries we serve. Our sustainability efforts encompass various initiatives:</p>

          <ul className="mt-5">
            <li className="mb-5"><span className="font-bold">Ethical Sourcing and Production:</span> We prioritize ethical sourcing and production practices across our business verticals, ensuring that our supply chains adhere to stringent ethical and environmental standards. From Together Threads to all facets of our operations, we strive for sustainability at every step.</li>
            <li className="mb-5"><span className="font-bold">Environmental Responsibility:</span> Reducing our carbon footprint is central to our sustainability agenda. We invest in eco-friendly practices, such as energy-efficient technologies, waste reduction, and responsible resource management, to minimize environmental impact.</li>
            <li className="mb-5"><span className="font-bold">Community Engagement:</span> We actively engage with local communities, supporting initiatives that promote education, environmental conservation, and social welfare. Through partnerships and programs, we aim to create a positive impact and foster community resilience.</li>
            <li className="mb-5"><span className="font-bold">Innovation for Sustainability:</span> Innovation drives our quest for sustainable solutions. We invest in research and development, seeking innovative approaches that balance business growth with environmental stewardship.</li>
            <li className="mb-5"><span className="font-bold">Transparency and Accountability:</span> We believe in transparency and accountability. Our sustainability efforts are continually evaluated and reported, holding ourselves responsible for making measurable progress towards our sustainability goals.</li>
          </ul>
          <p className="mt-5">Join Us in Our Sustainability Journey: Together Corporation invites you to join us in our commitment to sustainability. Together, we can make a meaningful difference, shaping a more sustainable future for generations to come.</p>


        </div>

      </div>
    </div>
  );
}
