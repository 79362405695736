import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { BsFillCheckCircleFill } from "react-icons/bs";

interface ContactFormProps {
  title?: string;
  desc?: string;
  className?: string;
}

export const ContactForm = (props: ContactFormProps) => {
  const form = useRef(null);
  const [onSuccess, setOnSuccess] = useState(false);
  const headTitle = props?.title ? props?.title : "We are here for questions or consulting";
  const headDesc = props?.desc ? props?.desc : "Please write to us.";
  const className = props?.className ? props?.className : "";

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs.sendForm('service_x5lrkqb', 'template_pa2jebt', e?.target, 'jzEOZkzRMqv1OoYBA')
      .then((result) => {
        console.log(result.text);
        setOnSuccess(true)

      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <>
      {!onSuccess ?
        <form ref={form} onSubmit={sendEmail} className={className}>
          <div className="space-y-12s">

            <div className="border-b border-gray-900/10 pb-6">
              <h2 className="text-2xl font-semibold leading-7 text-gray-900">{headTitle}</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">{headDesc}</p>

              <div className="mt-5 grid grid-cols-1 gap-2 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="user_name" className="block text-sm font-medium leading-6 text-gray-900">
                    Full name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="user_name"
                      id="user_name"
                      autoComplete="given-name" required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                    Phone
                  </label>
                  <div className="mt-2">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      autoComplete="given-name" required
                      title='Only Phone number'
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="user_email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="user_email"
                      name="user_email"
                      type="email"
                      autoComplete="user_email" required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                    Message
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="message"
                      name="message"
                      rows={3} required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={''}
                    />
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600">Elaborate your interest.</p>
                </div>
              </div>
            </div>
            <div className="mt-3 flex items-center justify-end gap-x-6">
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700"
              >
                Send
              </button>
            </div>
          </div>
        </form> :

        <div className='bg-white shadow-xl rounded p-14 flex flex-col justify-center items-center'>
          <div className='animate-bounce'>
            <BsFillCheckCircleFill size={'5rem'} color='#5cb85c' />
          </div>
          <div className='mt-5'>We've received your interest. Thank you!</div>
        </div>
      }
    </>
  );
}
